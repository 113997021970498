.ticket-box {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: white;
}

.ticket-title {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.ticket-des {
  text-align: left;
  font-size: 10px;
  width: 50%;
  color: rgb(133, 133, 133);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ticket-price {
  font-size: 12px;
  color: rgb(92, 182, 235);
  font-weight: bold;
  vertical-align:bottom;
}

.ticket-tips {
  font-size: 8px;
  margin-left: 5px;
  font-weight: 400;
  color: red;
}

.ticket-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
