

body{
  background-color: #e5e5e5;
}

@media screen and (max-width: 400px){
  .App{
    width: 100vw;
  }
}

@media screen and (min-width: 400px){
  .App{
    width: 400px;
  }
}

.App{
  margin: 0 auto;
  font-family: "微软雅黑";
  padding-bottom: 80px;
}


.test{
  width: 100%;
  height: 200px;
  background-color: aqua;
}