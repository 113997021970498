@media screen and (max-width: 400px){
  .sum-box{
    width: 100vw;
  }
}

@media screen and (min-width: 400px){
  .sum-box{
    width: 400px;
  }
}

.sum-box{
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: white;
  position: fixed;
  bottom: 0;
}

.sum-ticket{
  font-size: 12px;
}

.sum-ticket span{
  font-size: 14px;
  color: #16b9ff;
}

.sum-price{
  font-size: 14px;
}

.sum-price span{
  font-size: 16px;
  color: #16b9ff;
}