.buyer-box{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 170px;
  background-color: white;
}

.buyer-item{
  margin:10px 0;
}

.buyer-title{
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
}