.options-box{
  width: 100%;
}

.options-title{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color:  #494949;
  padding: 10px;
  font-size: 14px;
  background-color: #f2f2f2;
}